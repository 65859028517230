#payments_container{
    background-color: #a8dadc;
}
#payments_title{
    font-size:1.6rem;
    font-weight:600;
    background-color:#264653;
    padding:10px;
    color:white;
}
#payment_form{
    margin:20px 0px;
    background-color: white;
    padding:13px;
    border-radius:10px;
    box-shadow:#2646536F 0 0 40px 5px;
    border:3px #264653 solid;
}

#payment_form_cont{
    display:flex;
    justify-content:center;
}
#amount_text{
    margin:20px 0;
    font-weight:500;
    color:rgb(8, 90, 116);
}
#paybtn{
    margin:20px 0 0 0;
    background-color:#264653;
    color:white;
    border-radius:5px;
}
#payment_footer{
    background-color:#264653;
    height:20px;
}
@media only screen and (min-width:650px){
    #payment_form{
        min-width:400px;
        margin:50px 0;
    }
}