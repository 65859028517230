.back_journal{
    background-color:#dbdbdb;
    min-height:calc(100vh - 52px);
    overflow:hidden;
}
.back_journal form{
    margin:50px 0 0px 0;
    display:none;
    background-color:#022a44;
    flex-direction:column;
    border-radius:30px;
    border:solid 5px white;

}
.back_journal form h1{
    color:white;
    font-weight:500;
    margin-bottom:10px;
    padding-top:10px;
    text-align:center;
    
}
.back_journal h4{
    color:white;
    text-align:left;
    margin:15px 15px 0px 15px;
    font-weight:500;
    font-size:1.2rem;
}
.back_journal input{
    margin:15px;
    font-size:1.2rem;
    padding:5px;
    color:rgb(44, 44, 44);
    border-radius:10px;
}
.back_journal select{
    margin:15px;
    height:30px;
    
}

.back_journal input[type=submit]{
    background-color:#0dd872;
    color:white;
    border:none;
}
.back_journal input[type=file]{
    color:white;
}
.back_journal_table{
    position:relative;
    left:50%;
    transform:translateX(-50%);
    text-align:center;
    width:1250px;
    min-width:360px;
    border-collapse:separate;
}
.back_journal_table thead th{
    background-color:#022a44;
    color:white;
    border-radius:10px;
    border-collapse: separate;
}
.back_journal_table td{
    background-color:#d8e8f3;
    border-radius:10px;
    border-collapse:separate;
    vertical-align: middle;
    
}
#back_editor{
    margin:15px;
}
#add_new_journal{
    border:solid black 2px;
    border-radius:30px;
    margin:30px 0 0 10px;
    height:60px;
    width:200px;
    font-size:1rem;
    cursor:pointer;
    transition:all .2s;
    background-color:#dbdbdb;
    color:#022a44;


}
#add_new_journal:hover{
    color:white;
    background-color:#022a44;
}
#close_btn{
    cursor:pointer;
    position:absolute;
    transform:translate(1205px,10px);
}
.select_dropdown{
    background-color:white;
    height:10px;
    
}
.select_dropdown span{
    transform:translate(0,20px);
}

@media only screen and (max-width:1260px){
    #close_btn{
        transform:translate(calc(100vw - 69px),10px);
    }
    .back_journal_table{
        width:97vw;
    }
}
#editor_multiple_cont h2{
    color:white;
    font-size:1.5rem;
    text-align:center;
}
#editor_multiple_cont input{
    display:block;
    margin:15px;
    width:calc(100% - 30px);
    
}
#editor_multiple_cont textarea{
    display:block;
    margin:15px;
    border-radius:10px;
    width:calc(100% - 30px);
    
}
#indexing_cont h2{
    text-align:left;
    margin-left:30px;
    font-size:1.5rem;
    color:white;

}
#success_submitedit,#success_submitadd{
    position:fixed;
    z-index:100;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    opacity:0;
    transition:all 1s;
}









