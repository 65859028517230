/* #advanced_search_container{
    min-height:250px;
} */
#search_container{
    min-height:68px;
    margin:0;
}
/* #advanced_search_container h5{
    font-size:2rem;
    font-weight:500;
    text-align:center;
    margin:0 0 25px 0;
} */
.h_six{
    margin:10px 0 5px 0;
}
#search_container h5{
    font-size: 16px;
    font-weight: 600;
}
/* #advanced_search_form input[type=text]{
    height:30px;
    border-radius:8px;
    border:1px solid rgba(119, 118, 118, 0.611);
} */
#search_form input[type=text]{
    height:30px;
    width:180px;
    margin:5px;
    border-radius:8px;
    border:1px solid rgba(119, 118, 118, 0.611);
  
}
.search_select{
    height:30px;
    width:180px;
    margin:5px;
    border-radius:8px;
    border:1px solid rgba(119, 118, 118, 0.611);
    background-color:white;
    color:rgba(0,0,0,.2);
}
#select1,#select2{
    width:180px;
    margin:5px;
}
/* #advanced_search_form input[type=submit]{
    border:none;
    cursor:pointer;
    color:white;
    padding:6px 18px 6px 18px;
    font-weight:500;
    border-radius:5px;
} */
#search_form input[type=submit]{
    border:none;
    padding:6px 8px 6px 8px;
    border-radius:5px;
    cursor:pointer;
    color:white;
    margin-left:27px;
    padding:6px 18px 6px 18px;
}
/* #advanced_search_form a{
    font-size:.8em;
    font-weight:600;
    color: #4f5671;
} */
#search_form a{
    font-size:.8em;
    font-weight:600;
    color:#4f5671;
    margin-left:27px;
}
/* #advanced_search_form a:hover{
    text-decoration:underline;
} */
#search_form a:hover{
    text-decoration:underline;
}
#full_text{
    display:flex;
}
#semi_text{
    display:none;
}
input[type=text]::placeholder{
    font-size:.8em;
}
@media only screen and (max-width:1260px){
    #search_form input[type=text],#select1,#select2{
        width:14.5vw;
    }
    #search_form input[type=submit],#search_form a{
        margin-left:4vw;
    }
    #search_form input[type=submit]{
        padding:6px 1.8vw 6px 1.8vw;
    }
}
@media only screen and (max-width:1190px){
    #semi_text{
        display:flex;
    }
    #full_text{
        display:none;
    }
}
@media only screen and (max-width:860px){
    #search_container{
        display:none;
    }
    /* #advanced_search_container{
        display:none;
    } */
}
/* @media only screen and (min-width:942px){
    #advanced_search_form{
        margin-left:10px;
    }
} */
