
#peer_review_container{
    padding-right:10px;
}
#peer_review_container h1{
    text-align:center;
    margin-bottom:15px;
    margin-top:10px;
    font-size:1.6rem;
    color:#333333da;
}
#peer_review_container p{
    line-height:25px;
    color:rgb(34, 33, 33);
    font-size:.85rem;
}
#peer_review_container h3{
    color:#333333;
    margin-top:40px;
    margin-bottom:30px;
    font-weight:500;
    font-size:1rem;
}
#peer_review_container li{
    list-style-type:square;
    margin:10px;
    color:#333333;
    font-size:.85rem;
}
#peer_review_container strong{
    margin-right:20px;
}
#peer_review_container ul{
    margin-bottom:40px;
}
#peer_review_tree{
    height:1000px;
}
