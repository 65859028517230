#back_container{
    display:flex;
    justify-content:center;
    align-items:center;
    height:100vh;
}
#back_form{
    height:500px;
    width:500px;
    background-color: #003049;
    display:flex;
    flex-direction:column;
    align-items:center;
    min-width:360px;
}
#back_form h1{
    color:white;
    font-weight:500;
    font-size:2rem;
    margin:85px 0 40px 0;
}
#back_form input{
    display:block;
    margin:45px 0 0 0;
}
#back_form input[type=password],#back_form input[type=text]{
    height:40px;
    width:400px;
    font-size:1.3rem;
    border-radius:10px;
    min-width:300px;
    padding-left:15px;
    color:#012a4a;
}
#back_form input[type=submit]{
    background-color:#0077b6;
    color:white;
}
#back_form input::placeholder{
    font-size:1.3rem;
}
#back_login_failed{
    color:red;
    background-color:pink;
    padding:8px 30px;
    border-radius:30px;
    display:none;
    margin-bottom:-20px;
}

#back_navbar_h1{
    margin:7px 0 0 10px;
    font-size:1.5rem;
    font-weight:600;
    color:#38b000;
}
.navbar-item{
    cursor:pointer;
}
.navbar-item:hover{
    color:#105eb6;
}
#back_admin_user_container{
    background-color:#4D6A6D;
    min-height:calc(100vh - 52px);
    overflow:hidden;

}
.back_table{
    margin:0px auto;
    text-align:center;
}
.back_table th{
    color:white;
    font-size:1.3rem;
    vertical-align:center;
    line-height:3rem;
}
.back_table td{
    font-size:1.3rem;
}
.back_table input{
    font-size:1.3rem;
    font-weight:500;
    color:rgb(58, 58, 58);
    border:none;
    background-color:rgba(0,0,0,0);
    pointer-events:none;
}
#admin_change_pw,#cat_edit{
    text-align:center;
    background-color:#184e77;
    margin-top:50px;
    overflow:hidden;
    max-width:800px;
    position:relative;
    left:50%;
    transform:translateX(-50%);
    border-radius:30px;

}
#cat_edit{
    display:none;
}
#admin_change_pw h1,#cat_edit h1{
    color:white;
    font-weight:500;
    margin-top:20px;
    margin-bottom:20px;
    text-decoration: underline;
}
#admin_change_pw input,#cat_edit input{
    display:inline-block;
    margin:15px;
    font-size:1.2rem;
    padding:5px;
    color:rgb(44, 44, 44);
    border-radius:10px;
}
#admin_change_pw input[type=submit],#cat_edit input[type=submit]{
    transform:translateY(-7px);
    color:white;
}

#password_mismatch{
    color:red;
    background-color:pink;
    padding:6px 0;
    display:none;

}
.back_table_cont h1{
    color:white;
    text-align:center;
    font-weight:500;
    margin-top:10px;
}
.back_table_cont{
    max-width:800px;
    position:relative;
    left:50%;
    transform:translateX(-50%);
    border-radius:30px;
    background-color:#12263a;
    padding-bottom:25px;
    margin-top:50px;
}
#back_page_navbar_container{
    margin-bottom:52px;
    overflow:hidden;
}
#back_navbar_start{
    font-size: 15px;
}
@media only screen and (min-width:600px) and (max-width:800px){
    .back_table{
        width:100vw;
    }
}
@media only screen and (min-width:806px){
    .back_table{
        width:800px;
    }
}
@media only screen and (max-width:600px){
    .back_table_cont{
        overflow:scroll;
    }
}
@media only screen and (max-width:1260px){
    #back_form{
        width:40vw;
    }
    #back_form input[type=password],#back_form input[type=text]{
        width:35vw;
    }
}
@media only screen and (max-width:1260px){
    #back_navbar_start{
        margin-left:55px;
    }
}