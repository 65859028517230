#logo{
    max-height:2.3rem
}
#submit{
    padding:10px;
    font-size:15px;
}
#logo{
    margin-left:4px;
}
#search{
    display:none;
    right:66px;
    top:3px;
    cursor:pointer;
}
#navbar-burger{
    transform:translateX(-15px)
}
.header_front_icon_cont{
    margin-right:10px;
    margin-top:5px;
}
/* styles for search and its children */
#hf_search_container{
    display:none;
    position:fixed;
    width:100vw;
}
#hf_search_container h1{
    color:white;
    margin:10px;
    font-weight:600;
    font-size:1em;
}
.hf_select_1{
    float:right;
}
.hf_select_2{
    float:left;
}
.hf_select_1,.hf_select_2{
    width:48vw;
    margin:1vw;
    
}
#hf_basic_span_1{
    display:flex;
}
.hf_inp_text{
    margin:0 1vw;
    max-height:27px;
}
#hf_basic_span_2{
    width:100vw;
    margin-top:50px;
    display:flex;
    justify-content:space-between;
}
#hf_advanced_span_2{
    width:100vw;
    /* margin-top:50px; */
    display:flex;
    justify-content:space-between;
}
#hf_basic_span_2 input[type=submit],#hf_advanced_span_2 input[type=submit]{
    width:48vw;
    height:30px;
    margin:10px 1vw 10px 1vw;
    background-color:#333333;
    color:white;
    border:solid #333333;
    border-radius:7px;
}

#hf_basic_span_2 a,#hf_advanced_span_2 a{
    color:white;
    cursor:pointer;
    font-size:1em;
    font-weight:600;
    margin:14px auto 0 auto;  
}
#hf_basic_span_2 a:hover{
    text-decoration:underline;
}
#hf_advanced_span_2 a:hover{
    text-decoration:underline;
}
#hf_arrow_basic{
    color:white;
    height:30px;
    width:30px;
    background-color:blue;
    position:absolute;
    transform:rotateZ(45deg) translateY(-7px);
    right:80px;
}
#hf_form span{
    display:flex;
    justify-content:space-around;
    color:white;
    font-size:.85em;
}
#hf_form span h6{
    font-weight:600;
}
#hf_advanced_span_1{
    margin-top:53px;
}
#hf_advanced_span_3{
    margin-top:7px;
    margin-bottom:5px;
}
#hf_form input[type=text]::placeholder{
    color:rgb(107, 106, 106);
}

#front_page_navbar_container{
    margin-bottom:4.4rem;
    background-color:blue;
    overflow:hidden;
}
#front_page_navbar{
    width:100%;
    max-width:1260px; 
    left:50%;
    transform:translateX(-50%);
}
#navbar a{
    padding-left:0;
}
@media only screen and (min-width:1200px){
    #logo{
        max-height:3.3rem
    }
}
@media only screen and (max-width:1200px){
    #submit{
        /* margin-left:0px; */
        margin-top:20px;
        font-size:14px;
        padding:10px 13px 10px 13px;
    }
    .navbar-item{
        font-size:1.2em;
    }
    #front_page_navbar_container{
        margin-bottom:3.3rem;
    }

}
@media only screen and (max-width:50em){
    #search{
        display:block;
    }
    #hf_search_container{
        display:block;
    }
    #navbar-burger{
        transform:translatex(30px)
    }
}
@media only screen and (max-width:302px){
    #hf_arrow_basic{
        display:none;

    }
}
