@font-face{
    src:url('../../fonts/BalooThambi2-Medium.ttf');
    font-family:baloo;
}

#home_journal_intro{
    background:linear-gradient(90deg,#002661,#0cafcc);
    position:relative;
    border:solid 10px skyblue;
    border-radius:20px;
}

#home_journal_intro img{
    display:inline-block;
    height:240px;
    min-height:150px;
    vertical-align: middle;
    margin:20px;
    border-radius:10px;
}
#home_journal_text{
    line-height:1rem;
    position:absolute;
    display:inline-block;
    font-size:1rem;
    color:white;
    top:50%;
    transform:translateY(-50%);
    font-family:'Times New Roman', Times, serif;
}
#home_journal_intro h6{
    margin:3.5px 0px 3.5px 10px;
    font-family:'Times New Roman', Times, serif;
}
#home_journal_intro h1{
    margin:8px 0px 20px 10px;
    font-weight:500;
}

.home_styled_btn{
    padding:20px;
    margin:0;
    background-color:#012c66;
    border:none;
    color:white;
    cursor:pointer;
    font-size:18px;
    transition: all .2s linear;
}

.home_styled_btn:hover{
    background-color:#0ba6c5;
}
#home_btn_active{
    background-color:#0ba6c5;

}
#home_columns_column1_child{
    background-color:#012c66;
    padding:20px;
}
#home_columns_column1_child div:nth-child(1){
    text-align: center;
}
#home_columns_column2{
    padding-right:0;
    padding-left:.3rem;
}
#home_current_issue{
    margin-left:6px;
    display:inline-block;
}
#home_current_issue_display_volume{
    margin:10px 0 0 25px;
    color:rgb(255, 4, 87);
}

#home_archive_year{
    background-color:#2A9D8F;
    padding:7px;
    font-weight:bold;
    font-size:1.5rem;
    color:white;
    border-radius:10px;
    margin:20px 0;
    text-align:center;
}
#home_archive_issue{
    display:inline-block;
    background-color:white;
    border:solid #2A9D8F 2px;
    margin:5px;
    font-size:1rem;
    width:176px;
    padding:20px 0;
    border-radius:.25em;
    color:#2A9D8F;
    text-align:center;
    cursor:pointer;
    font-weight:bold;
    transition:background-color .2s,color .2s;
}
#home_archive_issue:hover{
    background-color:#1AAB8A;
    color:white;
}

.speech_bubble{
    text-align:center;
    background:linear-gradient(90deg, #2C5364, #203A43,#0F2027);
    padding:20px;
    border-radius:50px;
    display:inline-block;
    transform:translate(800px,-70px);
    color:white;
    font-family:baloo;
}
.speech_bubble::before{
    position:absolute;
    content:'';
    height:40px;
    width:40px;
    background:linear-gradient(90deg, #2C5364, #203A43,#0F2027);

    border-radius:50%;
    transform:translate(0px,60px);
}
.speech_bubble::after{
    position:absolute;
    content:'';
    height:25px;
    width:25px;
    border-radius:50%;
    transform:translate(-90px,70px);
    background:linear-gradient(90deg, #2C5364, #203A43,#0F2027);

}
#journal_editor_name{
    text-transform:capitalize;
    font-family:'Times New Roman', Times, serif;
    text-align:left;
    margin:0 20px;
}
#journal_editor_description{
    margin:0 20px;
    text-align:left;
    /* margin-left:10px; */
    font-size:13px;
    font-family:'Times New Roman', Times, serif;

}
#journal_editor_image_container{
    background-color:#264653;
    border-left:solid 5px rgb(0, 140, 255);
}
#indexing_section{
    margin:10px 4px 0px 4px;
}
#indexing_section img{
    /* max-width:200px; */
}
#indexing_section h2{
    text-align:center;
    font-family:'Times New Roman', Times, serif;
}
#indexing_section div{
    text-align: center;
}

/* .home_journal_text_semi{
    display:grid;
    grid-template-columns:1fr 3fr;
} */

.home_journal_text_semi{
    display:flex;
    flex-direction:row;
    width:100%;
}
.home_journal_text_semi h6:first-child{
    width:100px;
}






#editorial_board{
    margin:0;
}
#editorial_board_head{
    width:100%;
    text-align:center;
    background-color:rgba(124, 124, 124,.4);
    font-size:1.4rem;
    font-weight:500;
    padding:10px;
    
}
#chief_editor_head{
    font-size:1.4rem;
    margin-bottom:10px;
    font-weight:500;
}
#managing_editor_head{
    font-size:1.4rem;
    margin-bottom:10px;
    font-weight:500;
}
#associate_editors{
    width:100%;
    text-align:center;
    font-size:1.4rem;
    margin-bottom:10px;
    font-weight:500;
}
.editorial_box_size{
    margin:0 5px;
}

#author_instruction_cont h1{
    background-color:#002661;
    color:white;
    text-align:center;
    padding:5px;
}
#author_instruction_cont li a{
    text-decoration:none;
}
#author_instruction_cont li a div{
    background-color:#e8f3f8;
    width:100%;
    padding:10px;
    border-bottom:solid 2px #002661;
}





@media only screen and (max-width:850px){
    .home_styled_btn{
        padding:5px;
    }
    #author_instruction_cont{
        margin-top:10px;
    }
    
}


@media only screen and (max-width:1260px){
    .speech_bubble{
        position:absolute;
        right:0px;
        margin:10px;
        transform:translate(0,40px)
    }
}
@media only screen and (max-width:700px){
    .speech_bubble{
        display:none;
    }
}






@media only screen and (max-width:1260px){
    #home_journal_intro img{
        height:19vw;
    }


}

@media only screen and (max-width:800px){
    #home_journal_intro h6{
        font-size:.9rem;
        margin-top:2px;
        margin-bottom:2px;
        margin-left:0px;
        margin-right:2px;
    }
    #home_journal_intro h1{
        margin:0px 0px 15px 0px;
        font-weight:500;
    }
}
@media only screen and (max-width:482px){
    #home_journal_intro h6{
        font-size:.7rem;
        margin-top:.5px;
        margin-bottom:.5px;
    }
    .home_journal_text_semi h6:first-child{
        width:60px;
    }
    .home_journal_text_semi h6:last-child{
        width:39vw;
    }
}

@media only screen and (max-width:860px){
    #home_columns_column2{
        display:none;
    }
    #home_columns_column1{
        padding-right:0;
    }
    #indexing_section img{
        max-width:300px;
    }
}

