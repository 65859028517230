#session_journal_head{
    text-align:center;
    background-color: rgb(245, 156, 186);
    color:white;
    padding:5px;
}
#session_details{
    margin-top:5px;
    text-align:center;
    font-weight: 500;

}