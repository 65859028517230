#books_container{
    background-color: white;
}
#publication_email_search_cont{
    padding-top:0px;
}
#top_content_cont{
    padding-bottom:0px;
}
.multi_carousel_item{
    padding:0px;
    margin: 0;
}