#main_tile{
    background-color:#f5f5f5;
    margin:0 auto;
}
#main_tile h1{
    font-size:16px;
    font-weight:600;
    line-height:1.5;
    color:#313131;
    font-family:Arial;
}
#main_Select{
    margin-top:10px;
}
#main_center_tile_child{
    padding: 0;
}
#main_first_tile_child{
    width:100%;
}
#main_journal_cont{
    border-bottom:solid grey 1px;
    font-size:.76rem;
    margin:0;
    cursor:pointer;
    text-decoration:none;
}
#main_journal_cont:hover{
    text-decoration:underline;
}
#main_journal_cont img{
    height:50px;

}
#main_journal_cont h4{
    margin-left:13px;
    color:#098383;
}

#main_newsletter h1{
    font-size:1rem;
    color:skyblue;
}
#main_newsletter p,#main_newsletter h3,#main_newsletter a{
    font-size:.84rem;
    margin-top:10px;
    margin-bottom:10px;
}
#main_newsletter label{
    font-size:.9rem;
}
#main_newsletter input{
    margin:5px 0;
}

#main_numbers_box{
    height:85px;
    width:85px;
    margin:9px;
    color:white;
    background-image:linear-gradient(0deg,rgb(45, 123, 212),rgb(219, 216, 216));
    border-radius:20px;
    display:inline-block;
    overflow:hidden;
    

}
#main_numbers_box h3{
    text-align:center;
    font-size:1.2rem;
    padding-top:10px;
    padding-bottom:5px;
    
}
#main_numbers_box p{
    text-align:center;
    font-size:.7rem;
}
#main_nums_container{
    /* uncomment or comment below padding to get 3*2 box else 2*3 box */
    padding: 0;
    text-align:center;
}
#main_nums_container h1{
    font-size:1.4rem;
    /* margin-bottom:20px; */
    border-bottom:.5px solid rgba(0,0,0,.2);
    color:rgba(0,0,0,.8);
    font-weight:500;
}
#main_caro_text{
    background-color:rgb(10, 55, 73);
    font-size:.85rem;
    /* activate if you want no opacity in carousel text */
    opacity:1;
}
#article_child_nav{
    display:flex;
    justify-content: center;
    
}

#main_journo_title{
    text-decoration:none;
    display:block;
    margin-top:10px;
    font-size:1rem;
    font-weight:500;
    line-height:1.5rem;
    color:rgb(51, 51, 51);
}
#article_child_nav span{
    margin:0px 15px 0 0;
}
#article_child_nav h5{
    line-height:30px;
    background-color:#c9184a;
    padding:1px 10px;
    border-radius:10px;
    color:rgb(255, 255, 255);
    margin-right:7px;
    font-size:.7rem;
    
}
#article_child_nav h4{
    line-height:30px;
    background-color:#f1d388;
    padding:1px 10px;
    border-radius:10px;
    color:#444;
    font-size:.7rem;

}
#article_child_nav h3{
    flex-grow:1;
    visibility:hidden;
}
#article_child h6{
    display:inline-block;
    margin-right:4px;
    font-size:.8rem;
}
#main_author{
    text-decoration:none;
    color:rgb(11, 72, 97);
    font-weight:500;
    font-size:.8rem;
}
#article_child h2{
    font-size:.76rem;
    color:grey;
    line-height:1rem;
    margin:5px 0;
}
#main_abstract{
    color:rgb(0, 89, 255);
    font-size:.87rem;
    font-weight:500;
    text-decoration:none;
    margin-right:7px;
}
#article_child p{
    font-size:12px;
    line-height:1.5;
    margin-bottom:10px;

}
#more{
    display:none;
}
#read_more{
    color:rgb(0, 89, 255);
    text-decoration:none;
}
#read_more:hover{
    text-decoration:underline;
}
#article_child_caro_whole_container a{
    text-decoration:none;
    color:rgb(0, 89, 255);
    font-size:.8rem;
}
#article_child_caro_whole_container a:hover{
    text-decoration:underline;
}
.caro_img_article{
    height:300px;
    cursor:pointer;
}
.caro_img_article img{
    object-fit:contain;
    height:100%;
    width:100%;
  
}
.big_img_article{
    height:500px;
    width:100%;
   
}
.big_img_article img{
    object-fit:contain;
    height:100%;
}
#big_screen_carrow{
    height:100vh;
    width:100vw;
    background-color:rgba(0,0,0,.5);
    position:fixed;
    top:0;
    left:0;
    z-index:32;
    display:none;
    justify-content:center;
    align-items:center;
}
#big_screen_carrow_container{
    height:50vh;
    width:100vw;  
    transform:translateY(-20%)  
}
#main_close_btn{
    transform:translateX(calc(50vw - 20px));
    cursor:pointer;
}
#load_more_article{
    text-decoration:none;
    margin-left:13px;
    color:rgb(0, 76, 255);
}
#load_more_article:hover{
    text-decoration:underline;
}

#page_nuumber{
    font-size:13px;
    font-family:'Times New Roman', Times, serif;
    font-style:italic;
    font-weight:100;
    color:rgb(31, 31, 31);
}

@media only screen and (max-width:860px){
    #main_journal_cont{
        display:none;
    }
    #main_first_tile_child{
        display:none;
    }
    #main_newsletter{
        display:none;
    }
    #indexing_container{
        display:none;
    }
}
