
#plagiarism_policy_container h1{
    color:#424242;
    text-align:left;
    font-size:1.6rem;
    margin:0px 0 10px 60px;
    padding-top:30px;
}
#plagiarism_policy_container p{
    margin:0 60px;
    line-height:30px;
    padding-bottom:40px;
    font-size:.85rem;
    
}
#plagiarism_container{
    background-color: white;
}
.footer_last{
    position:absolute;
    bottom:0;
}

#authors_right_h1{

}
#authors_right_h2{
}
#plagiarism_container{
    /* min-height:2000px; */
}
@media only screen and (max-width:1200px){
    #plagiarism_policy_container h1{
        padding:15px 0 0px 0;
    }
    #authors_right_h2{
        margin-top:-15px;
    }
}
@media only screen and (max-width:768px){
    #plagiarism_policy_container p{
        margin:0 30px 0 30px;
    }
    #plagiarism_policy_container h1{
        text-align:left;
        margin-left:30px;
    }

}

