#manage_pages{
    background-color:skyblue;
    min-height:100vh;
    overflow:hidden;
}
#modal_card{
    width:100vw;
    max-width:1260px;
    
    
}
#add_new_journal_page{
    margin:10px;
    
}