import{
    font-family:baloo;
}
@font-face{
    src:url('../../fonts/BalooThambi2-Medium.ttf');
    font-family:baloo;

}
.search_result_result{
    text-align:center;
    font-size:2.4rem;
    margin:20px;
    text-decoration:underline;
    font-weight:500;
    font-family:baloo;
}
.search_result{
    overflow:hidden;
}
.thinline{
    width:100vw;
    background-color:rgb(36, 36, 36,.15);
    height:2px;
    
}