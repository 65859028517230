#about_ai h1{
    font-size:1.8rem;
    margin-bottom:15px;
    color:#3d3c3cea;
}
#about_ai p{
    margin-bottom:30px;
    color:#333333;
    line-height:25px;
    font-size:.9rem;
}
#bullet_list{
    list-style-type:square;
}
