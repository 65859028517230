.table_img_container{
    padding:0;
}
.journal_front_image{
    max-height:100px;
    min-width:50px;
}

#journal_link{
    text-decoration:none;
}
#journal_link:hover{
    text-decoration:underline;
}
#journals_list_cont{
    width:100%;
    padding-left:30px;
    background-color:white;
}
#journals_list_cont div:first-child{
    overflow:scroll;
}
#journals_table tbody tr td{
    font-size:14px;
}
#journals_table thead th h2{
    font-size:17px;
}
@media only screen and (max-width:860px){
    #journals_table{
        padding:0;
    }
}
@media only screen and (max-width:612px){
    #journals_table{
        font-size:9px;
    }
    #journals_table thead th h2{
        font-size:9px;
    }
    #journals_list_cont{
        padding:0;
    }
}
