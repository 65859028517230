
#processing_title_box{
    background-color:#037ef3;
}
#processing_title_box h1{
    font-size:1.6rem;
    text-align:center;
    padding:10px;
    color:white;
    font-family:monospace;
}
#processing_fees_container div{
   padding:0; 
}
#processing_fees_container div p{
    margin:30px 20px 20px 20px;
    line-height: 1.2rem;
    color:rgb(77, 77, 77);
    font-size:.85rem;
}
#article_fees_boxes{
    margin: 0;
    color:#414141;
}
#article_fees_box1 div{
    margin:10px;
    border:solid 3px rgba(85, 85, 85, 0.219);
    border-radius:10px;
    transition:box-shadow .2s;


}
#article_fees_box1 div:hover{
    box-shadow:0 0 5px 5px grey;
}
#article_fees_box1 div h1{
    text-align:center;
    font-size:2rem;
    margin-top:15px;
    margin-bottom: 30px;
    color:#414141;
    font-weight:900;
    
}
#article_fees_box1 div h4{
    text-align:center;
    margin-bottom:20px;
    color:grey;
    font-size:1rem;
    margin-left:10px;
    margin-right: 10px;

}
#article_fees_box1 div h3{
    text-align:center;
    font-size:1.3rem;
    font-weight:900;
    margin-top:20px;

}
#article_fees_box1 div button{
    margin:0 auto;
    display:block;
    margin-top:30px;
    margin-bottom:25px;
    font-weight:500;
    background-color:#037ef3;
    color:white;
}


#article_fees_box2 div{
    margin:10px;
    border:solid 6.5px #2c9f45;
    border-radius:10px;
    transition:box-shadow .2s;


}
#article_fees_box2 div:hover{
    box-shadow:0 0 5px 5px #2fad09;
}
#article_fees_box2 div h1{
    text-align:center;
    font-size:2rem;
    margin-top:15px;
    margin-bottom: 30px;
    color:#414141;
    font-weight:900;
    
}
#article_fees_box2 div h4{
    text-align:center;
    margin-bottom:20px;
    color:grey;
    font-size:1rem;
    margin-left:10px;
    margin-right: 10px;

}
#article_fees_box2 div h3{
    text-align:center;
    font-size:1.3rem;
    font-weight:900;
    margin-top:20px;
    color:#2c9f45;

}
#article_fees_box2 div button{
    margin:0 auto;
    display:block;
    margin-top:25px;
    margin-bottom:30px;
    font-weight:500;
    background-color:#2c9f45;
    color:white;
}
table{
    margin-top:30px;
    margin-bottom:10px;
    
}
th{
    border:solid 2px grey;
    padding:10px;
    background-color:#12263A;
}
th h2{
    color:white;
    font-size:1rem;
}
td{
    border:solid 2px grey;
    padding:10px;
    background-color:#F4EDEA;
    
    
}
#article_processing_table{
    width:100%;
}
#article_processing_table td{
    font-size:.9rem;
}
