@font-face{
    font-family:material icons;
    src:url("../../fonts/MaterialIcons-Regular.woff") format('woff');
}
#banner{
    height:236px;
    background-color:violet;
}
/* inherited css from mdpi */
.hero-container .hero.hero-homepage-if {
    /* background: url(/resources/front_page/main_banner.jpg) #015077; */
    background-size: auto;
    background-size: 1260px 240px;
}
.hero {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hero-container .hero {
    height: 240px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
}
.hero-container .hero__description {
    position: relative;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 15px;
}
.hero-container .hero__description {
    position: relative;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 15px;
}
.hero__description{
    margin-top:18px;
}
.hero-container .hero__description__link {
    position: absolute;
    display: block;
    font-size: 16px;
    line-height: 1.3;
    right: 0;
    bottom: 0;
    color: #fff;
}
#linkam:hover{
    text-decoration:underline;
}
@media only screen and (max-width:50em){
    .hero-container .hero {
        padding: 30px 20px;
    }
    .hero-container .hero__header {
        font-size: 28px;
        line-height: 28px;
    }
    #linkam{
        display:none;
    }
}
@media only screen and (min-width:74.375em){
    .hero-container .hero {
        padding: 60px 45px;
    }
    .hero__header {
        font-size: 34px;
        line-height: 34px;
    }
    .show-for-medium-up{
        display:inherit !important;
    }
}

@media only screen and (min-width:50.063em){
    .hero-container .hero {
        padding: 60px 45px;
    }
    .hero__header {
        font-size: 34px;
        line-height: 34px;
    }
}
.hero-container .hero__description__link i {
    line-height: 24px;
    font-size: 16px;
}
.material-icons {
    font-family: material icons;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    /* -webkit-font-feature-settings: 'liga'; */
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
}













