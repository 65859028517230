#books_list_cont{
    background-color: #87CEEB;
    min-height: calc(100vh - 52px);
    overflow: hidden;
}
#image_warning,#image_warning_edit{
    color:white;
    background-color:rgb(255, 77, 106);
    padding:10px;
    border-radius:5px;
    display:none;
}
