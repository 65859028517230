#journal_cats{
    background-color:#D6FFB7;
    overflow:hidden;
    min-height:calc(100vh - 52px);
}
#journal_cat_table{
    background-color:#55a630;
}
#journal_cat_table table th{
    background-color:#91CB3E;
}
#journal_cat_table table td{
    background-color:#d4f5a6;
}
@media only screen and (min-width:360px) and (max-width:599px){
    #journal_cat_table table{
        width:100vw;
    }
}