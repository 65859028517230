#thesis_container h1{
    font-size:1.6rem;
    color:#424242;
    margin-bottom:20px;
    
}
#thesis_container p{
    color:#515151;
    line-height:25px;
    margin-bottom:30px;
    font-size:.9rem;
}
#thesis_container h4{
    font-weight:600;
    margin-bottom:17px;
    font-size:1.15rem;
    color:#424242;
}
#thesis_container a{
    color:rgb(0, 110, 255);
    text-decoration:none;
}
#thesis_container p strong{
    color:#333333;
}
#thesis_bro_container{
    background-color:#e9e9e9;
    border:solid .5px rgba(0, 0, 0, 0.411);
    border-radius:10px;
}
#thesis_bro_container div{
    margin:5px;
}
#thesis_bro_container img{
    max-width:110px;
}
#thesis_bro_container h5{
    line-height:30px;
    font-size:.9rem;
}
#thesis_bro_container h5 strong{
    color:#993300;
    font-weight:500;
    font-size:.9rem;
}
#thesis_bro_child2{
    padding:0 0 0 10px;
    font-size:.9rem;
}
