/* #footer_container{
    background-color:blue;
} */
#footer_img{
    position:absolute;
    height:240px;
    background-repeat:no-repeat;
    width:100vw;
    
}

#footer_carousel{
    height:210px;
    color:white;
    text-align:start;
    margin:30px 0 0 50px;
    
}
#footer_carousel h1{
    font-size:2.3rem;
    margin:0 0 10px 0;
    padding:0;
    font-weight:500;

}
#footer_carousel p{
    font-size:16px;
    font-weight:600;
    margin-bottom:3px;
    max-width:740px;

}
#footer_carousel button{
    color:white;
    background-color:transparent;
    border:solid white 1px;
    padding:8px;
    border-radius:6px;
    transition:color .2s,background-color .2s;
    cursor:pointer;
    margin:6px 6px 6px 0;
}
#footer_carousel button:hover{
    background-color:white;
    color:black;
}
@media only screen and (max-width:438px){
    #footer_carousel h1{
        font-size:1.8rem;
    }
    #footer_carousel{
        margin-left:10px;
    }
}
@media only screen and (max-width:860px){
    #footer_carousel p{
        display:none;
    }
}
#footer_journal_index{
    transform:translateY(-10px);
}
#footer_journal_index_desktop{
    background-color:white;
    min-height:200px;
    margin:-.75rem 0 0 0;
}

#footer_journal_index_desktop div:nth-child(1) h1{
    font-size:1.4rem;
    font-weight:600;
    margin-bottom:10px;
    margin-left:40px;
}

#footer_journal_index_desktop div:nth-child(1) h2{
    display:block;
    margin-top:4px;
    font-size:.9rem;
    color:black;
    font-weight:500;
    margin-left:40px;
}
#footer_journal_index_desktop div:nth-child(1) h2:hover{
    text-decoration:underline;
    cursor:pointer;
}


#footer_journal_piece img{
    height:50px;
}
#footer_journal_piece h4{
    color:#076077;
}
#footer_journal_piece div{
    overflow:hidden;
    border-bottom:solid 1px rgba(128, 128, 128, 0.472);
}
/* journal list for mobile */
#footer_journal_piece_2{
    display:inline-block;
}
#footer_journal_piece_2 img{
    height:50px;
    margin:10px;
}
#footer_journal_piece_2 h4{
    color:#076077;
    margin:auto 0;
    margin:10px;
}
#footer_journal_piece_2 div{
    display:flex;
    width:240px;
}
@media only screen and (max-width:860px){
    #footer_journal_index_desktop{
        display:none;
    }
}
@media only screen and (min-width:860px){
    #footer_journal_index_mobile{
        display:none;
    }
}
#footer_footer{
    background-color:#454c66;
    padding:10px 0 0 10px;
}
.footer_footer_containers{
    color:white;
    font-size:13px;

}
.footer_footer_containers a{
    color:white;
}
.footer_footer_containers h1{
    font-size:16px;
    margin-bottom:5px;
    font-weight:600;
}
#footer_footer_last{
    height:40px;
    background-color:#333333;
    display:flex;
    align-items:center;
}
#footer_footer_last a{
    color:white;
    vertical-align: center;
    margin:0 10px;
    font-size:13px;
}

#footer_footer_last h6{
    vertical-align: center;
    color:white;
    flex-grow:1;
    margin-left:10px;
    font-size:13px;
}
.footer_link:hover{
    text-decoration:underline;
}
.text_justify{
    text-align:justify;
}
#contact_us2 h2{
    display:inline-block;
    width:50px;
    margin-right:20px;
}
#about_us_link:hover{
    text-decoration:underline;
    
}


